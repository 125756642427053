import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    this.index = this.element.querySelectorAll('tr').length - 1
    this.addNewRow()

    this.element.addEventListener('specification-list-row:modified', (event) => {
      this.addRowIfNeeded(event)
    })
  }

  addRowIfNeeded (event) {
    const row = event.target
    const isNew = row.dataset.storageState === 'new'
    const modState = row.dataset.modState

    if (isNew && modState === 'modified') {
      this.addNewRow()
    }
  }

  addNewRow () {
    const template = document.querySelector('#specification-list-row-template')
    const clone = template.cloneNode(true)
    clone.removeAttribute('id')

    this.index += 1

    // Replace INDEX in name and id
    clone.querySelectorAll('[name]').forEach((el) => {
      el.name = el.name.replace(/\[INDEX\]/g, `[${this.index}]`)
    })

    clone.querySelectorAll('[id]').forEach((el) => {
      el.id = el.id.replace(/INDEX/g, `${this.index}`)
    })

    // Reset mod + storage state
    clone.dataset.storageState = 'new'
    clone.dataset.modState = 'unmodified'

    // Reset form inputs
    clone.querySelectorAll('input').forEach((el) => {
      if (el.type === 'hidden') {
        el.value = ''
      } else if (el.type === 'file') {
        el.value = ''
      } else {
        el.value = ''
      }

      el.disabled = false
    })

    // Delete button
    const deleteButton = clone.querySelector('button')
    deleteButton.disabled = true
    deleteButton.textContent = 'delete'

    // Append to table body
    this.element.appendChild(clone)
  }

  removeRow (rowElement) {
    rowElement.remove()
  }
}
