import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileInput", "valueInput"]
  static values = {
    hasOriginalDocument: Boolean
  }

  connect() {
    // Called when the controller is connected to the DOM.
  }

  confirmBeforeFileSelect(event) {
    const valueField = this.valueInputTarget

    // If there's already a value, ask for confirmation
    if (!this.hasOriginalDocumentValue && valueField.value.trim() !== "") {
      const confirmed = confirm("There is already a value entered. Are you sure you want to replace it by selecting a file?")
      if (!confirmed) {
        event.preventDefault()
        return
      }
    }

    // When a file is selected (after confirmation), disable the value field and show the file name
    event.target.addEventListener("change", (e) => {
      const file = e.target.files[0]

      if (file) {
        valueField.value = file.name
        valueField.disabled = true
      }
    }, { once: true }) // Once prevents multiple attachments
  }
}
