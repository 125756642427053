import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['nameInput', 'valueInput', 'fileInput', 'destroyField', 'deleteButton']

  connect () {
    this.originalName = this.nameInputTarget?.value
    this.originalValue = this.valueInputTarget?.value

    this.originalFile = this.fileInputTarget?.files[0] || null
    this.originalFileName = this.fileInputTarget?.files[0]?.name || null

    this.newRecord = this.element.dataset.storageState === 'new'


    this.updateModState()
  }

  handleFieldChange () {
    this.updateModState()
  }

  handleFileSelect (event) {
    const file = event.target.files[0]

    if (file) {
      // Disable valueInput and set it to file name
      if (this.hasValueInputTarget) {
        this.valueInputTarget.value = file.name
        this.valueInputTarget.disabled = true
      }

      this.updateModState()
    } else {
      // No file selected, re-enable the value field
      if (this.hasValueInputTarget) {
        this.valueInputTarget.disabled = false
        this.valueInputTarget.value = this.originalValue
      }

      this.updateModState()
    }
  }

  deleteExisting (event) {
    event.preventDefault()

    if (this.newRecord) {
      // If new, remove from DOM entirely
      this.element.remove()
      return
    }

    // Revert field values
    if (this.hasNameInputTarget) {
      this.nameInputTarget.value = this.originalName
      this.nameInputTarget.disabled = true
    }

    if (this.hasValueInputTarget) {
      this.valueInputTarget.value = this.originalValue
      this.valueInputTarget.disabled = true
    }

    if (this.hasFileInputTarget) {
      this.fileInputTarget.value = '' // Clear file input selection
    }

    // Mark for deletion
    this.element.dataset.storageState = 'deleted'
    this.destroyFieldTarget.value = 'true'

    // Change button to undelete
    this.deleteButtonTarget.textContent = 'undelete'
    this.deleteButtonTarget.setAttribute('data-action', 'click->specification-list-row#undeleteExisting')

    // Unmodified since we're deleting it
    this.element.dataset.modState = 'unmodified'
  }

  undeleteExisting (event) {
    event.preventDefault()

    // Restore name
    if (this.hasNameInputTarget) {
      this.nameInputTarget.value = this.originalName
      this.nameInputTarget.disabled = false
    }

    // Restore value
    if (this.hasValueInputTarget) {
      this.valueInputTarget.value = this.originalValue

      if (this.fileExistsOnServer()) {
        this.valueInputTarget.disabled = true
      } else {
        this.valueInputTarget.disabled = false
      }
    }

    if (this.hasFileInputTarget) {
      this.fileInputTarget.value = '' // No new file selected yet
    }

    this.element.dataset.storageState = 'existing'
    this.destroyFieldTarget.value = 'false'

    // Change button back to delete
    this.deleteButtonTarget.textContent = 'delete'
    this.deleteButtonTarget.setAttribute('data-action', 'click->specification-list-row#deleteExisting')

    this.updateModState()
  }

  updateModState () {
    let modified = false

    // Check if name has changed
    if (this.hasNameInputTarget && this.nameInputTarget.value !== this.originalName) {
      modified = true
    }

    // Check if value has changed (and not disabled because we rely on file instead)
    if (this.hasValueInputTarget &&
        !this.valueInputTarget.disabled &&
        this.valueInputTarget.value !== this.originalValue) {
      modified = true
    }

    // Check if file has changed by filename comparison
    if (this.hasFileInputTarget) {
      const currentFile = this.fileInputTarget.files[0]
      const currentFileName = currentFile?.name || null

      if (currentFileName !== this.originalFileName) {
        modified = true
      }
    }

    // Set the data attribute
    this.element.dataset.modState = modified ? 'modified' : 'unmodified'

    // Emit event **only if** this is a new record and first time modified
    if (this.newRecord && modified && !this.hasFiredModifiedEvent) {
      this.hasFiredModifiedEvent = true
      this.element.dispatchEvent(new CustomEvent('specification-list-row:modified', { bubbles: true }))
    }

    // Enable the delete button if modified
    if (this.hasDeleteButtonTarget && modified) {
      this.deleteButtonTarget.disabled = false
    }
  }

  fileExistsOnServer () {
    // True if this spec originally had an attached file
    return this.originalValue && this.valueInputTarget.disabled
  }
}
